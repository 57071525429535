import React from 'react'
import OnlyLogo from "../../static/images/logo/logo-only.svg"
import Book from "../../static/images/worksPage/book.svg"
import FountainPenIcon from "../../static/images/blogPageImages/fountain-pen.svg"
import FeatherIcon from "../../static/images/blogPageImages/feather.svg"
import LetterIcon from "../../static/images/contactPageImages/letter.svg"
import PriceTagIcon from "../../static/images/pricePageImages/tag.svg"
import WorkFlowIcon from "../../static/images/workFlowPage/setting-arrow.svg"
//import SdgsIcon from "../../static/images/sdgsPage/sdgs-monotein.svg"

const contents = (page, categoryName) => {
    switch(page){
        case "Service": 
            return { id: 0, h1: "Service", h2: "コンバージョンのアップや問い合わせ数の増加など、ウェブサイトで達成すべき「ゴール」を実現するハイパフォーマンスなウェブサイト制作を専門にしています。<br/><a href=\" /business/?r=mono-ser \" style=\"text-decoration: underline color: black\">事業について詳しくはこちら</a>", icon: "" }
        case "Works": 
            return { id: 1, h1: "Works", h2: "直近の制作実績です。ウェブサイト（Jamstack/ヘッドレスCMS）からウェブアプリケーション（SPA）まで、結果を出すパフォーマンスにこだわって制作しています。", icon: Book }
        case "Blog":
            return { id: 3, h1: "monotein blog", h2: "React、Next.jsに関するビギナー向けコンテンツ、海外の最新テクノロジーなどをわかりやすく紹介", icon: FountainPenIcon }
        case "BlogCategory":
            return { id: 4, h1: `${categoryName}`, h2: `「${categoryName}」カテゴリーの記事です。`, icon: FeatherIcon }
        case "Contact":
            return { id: 5, h1: "Contact", h2: "制作のご相談、不明点、サービスについてなどお気軽にお問い合わせください。", icon: LetterIcon }
        case "FreeContact":
            return { id: 6, h1: "無料相談", h2: "「ページの表示が遅くて困っている...」<br/>「ホームページを作ったけどアクセスが集まらない...」<br/>こんなお悩みを解決します。<br/>まずはお気軽にお問い合わせください。", icon: LetterIcon }
        case "Price":
            return { id: 7, h1: "Price", h2: "<span style=\"font-family: Quicksand\">monotein</span>が提供する各サービスの価格です。<br/>お客さまのニーズに柔軟に対応いたしますので、まずはお気軽にご相談ください。", icon: PriceTagIcon } 
/*         case "WorkFlow":
            return { id: 8, h1: "Work Flow", h2: "ウェブ制作の多くは漠然としたご要望から始まりますが、プロセスを一歩一歩踏んで進んでいくことで、進むべき方向が見えてきます。", icon: WorkFlowIcon }  */
/*         case "SDGs":
            return { id: 9, h1: "ウェブ制作事業とSDGsへの取り組み", h2: "monoteinが進めるSDGs（世界を変えるための17の目標）への取り組みを紹介します。", icon: SdgsIcon }  */
        case "IntContact":
            return { id: 10, h1: "Contact", h2: "Feel free to ask about website development, Jamstack, Headless CMS, web application and web marketing. <br/>We will get back to you soon!", icon: LetterIcon }   
        default:
    }
}

const PageHeader = ({ page, categoryName }) => {
    const content = contents(page, categoryName)
    return (
      <>
        <div className="page-header page-header-except-index">
            <div className="container">
                <h1>{content.h1}</h1>
                {page === "Service" ? 
                    <img src={OnlyLogo} alt="Logo" style={{margin: "-20px 0 15px 0"}}/> 
                    : 
                    <img src={content.icon} alt={content.h1} style={{margin: "-20px 0 15px 0"}}/> 
                }
                <h2 dangerouslySetInnerHTML={{ __html: content.h2 }} className="pageHeader-des"/>
            </div>
        </div>
      </>
    )
}

export default PageHeader