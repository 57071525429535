import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/hero"
//import ContactFAQ from "../components/contactFAQ"
import MailIcon from "../../static/images/commonImg/mail.svg"
//import InstaLogo from "../../static/images/commonImg/instagram.svg"
//import LineLogo from "../../static/images/commonImg/line.svg"

const Contact = () => {
    return (
        <Layout page="Contact">
            <Seo title="Contact" 
                description="monoteinは【待ち時間０秒】の高速ウェブサイト制作を行っています。高SEO、高エンゲージメント、高CVのウェブサイト制作、英語版サイト制作などお気軽にお問い合わせください。"
            />
             <div className="contact">
                <PageHeader page="Contact"/>
                <div className="container">
                    <div className="content">
{/*                         <div className="row">
                            <div className="col-12">
                                <ContactFAQ />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-7">
                                <form name="contact" method="POST" data-netlify="true" action="/success/?r=contact" data-netlify-honeypot="bot-field">
                                    <input type="hidden" name="form-name" value="contact"/>
                                    <div>
                                        <label htmlFor="name">お名前</label>  
                                        <input type="text" name="name" id="name"　placeholder="三好アキ" required/>
                                        <label htmlFor="email">Eメール</label>
                                        <input type="email" name="email" id="email" placeholder="contact@monotein.com" required/>
                                        <label htmlFor="textarea">ご用件</label>
                                        <textarea name="message" rows="10" id="textarea" placeholder="ご自由にお書きください。" required></textarea>
                                        <br/> 
                                        <button type="submit" className="contact-button">送信</button>
                                    </div>
                                </form>   
                            </div>
                            <div className="col-5">
                                <div className="contact-email">
                                    {/* <p>インスタグラムのDM、ラインからもご連絡いただけます。</p>
                                    <p>
                                        <a href="/cu3tfwv45sr9jvei9nm2/?r=ig-business=contact" rel="noopener noreferrer" target="_blank"><img src={InstaLogo} alt="instagram"/></a>
                                        <a href="/cu3tfwv45sr9jvei9nm2/?r=ig-business=contact" rel="noopener noreferrer" target="_blank">@monotein</a>
                                    </p> */}
{/*                                     <p>
                                        <a href="/cu3tfwv45sr9jvei9nm2/?r=ig-business=contact" rel="noopener noreferrer" target="_blank"><img src={LineLogo} alt="line"/></a>
                                        <a href="/cu3tfwv45sr9jvei9nm2/?r=line-business=contact" rel="noopener noreferrer" target="_blank" className="a-tag-deco">コチラ</a>をクリックし、（QRコード読み取り後に）「友達に追加」を押してご連絡ください。
                                    </p> */}
                                    {/* <hr/> */}
                                    <p>メールでのお問い合わせは下記アドレスにお送りください。</p>
                                    <p>
                                        <img src={MailIcon} alt="mail-icon"/>
                                        <a href="mailto:contact@monotein.com">contact@monotein.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Contact